<template>
  <div id="app">
    <notifications group="alerts" position="bottom right" />
    <Container>
      <Navbar>
        <img :src="logo" alt="Zillaz NFT Logo" />
      </Navbar>
      <Main>
        <h1>Breeding</h1>
        <ImagesContainer>
          <div class="zilla-card">
            <img
              v-if="Object.keys(nftSelected1).length == 0"
              :src="zilla"
              alt=""
            />
            <img v-else :src="nftSelected1.image" alt="" />
            <div class="card-body">
              <button
                @click="loadNewNfts"
                data-bs-toggle="modal"
                data-bs-target="#modalSelect1"
                v-if="wallet"
              >
                select
              </button>
              <button v-else @click="errorNoWallet">select</button>
            </div>
          </div>
          <div class="middle-container">
            <div v-if="!wallet">
              <ConnectButton
                @click="showOptions = !showOptions"
                class="wallet-adapter-button"
                v-if="!wallet"
                >Connect Wallet</ConnectButton
              >
              <div v-if="showOptions" class="wallet-options" style="">
                <div class="wallet-option" @click="connectWallet('solana')">
                  <img :src="phantom_icon" />&nbsp;Phantom
                </div>
                <div class="wallet-option" @click="connectWallet('solflare')">
                  <img :src="solflare_icon" />&nbsp;Solflare
                </div>
              </div>
            </div>
            <div class="breed" v-else>
              <BreedButton @click="breedNow">
                <img :src="egg" alt="" />
                Breed
              </BreedButton>
              <div class="details">
                <img :src="radLogo" alt="" />
                {{this.price}} $RAD
              </div>
            </div>
          </div>
          <div class="zilla-card">
            <img
              v-if="Object.keys(nftSelected2).length == 0"
              :src="zilla"
              alt=""
            />
            <img v-else :src="nftSelected2.image" alt="" />
            <div class="card-body">
              <button
                @click="loadNewNfts"
                data-bs-toggle="modal"
                data-bs-target="#modalSelect2"
                v-if="wallet"
              >
                select
              </button>
              <button v-else @click="errorNoWallet">select</button>
            </div>
          </div>
        </ImagesContainer>
      </Main>
    </Container>
    <div class="modal" id="modalSelect1">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header d-block">
            <div class="d-flex justify-content-between align-items-center">
              <h3 class="fw-bold mb-0">Breed NFT</h3>
              <img
                src="/img/icon/Close.svg"
                class="primary-icon"
                data-bs-dismiss="modal"
                alt=""
              />
            </div>
            <p class="m-0 text-muted">Select the first NFT you want to breed</p>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="text-center" v-if="Object.keys(nfts).length == 0">
                <i v-if="!errorNfts" class="fas fa-spin fa-spinner"></i>
                <div v-else class="text-center error-text">
                  You don't have at least 2 NFTs available to Breed!
                </div>
              </div>
              <div
                @click="selectNft(nft, 1)"
                class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3"
                v-for="nft in loadedNfts()"
                :key="nft.id"
                data-bs-dismiss="modal"
              >
                <div class="list-collection">
                  <div
                    class="collection-img"
                    :style="'background-image: url(' + nft.image + ');'"
                  ></div>
                  <div class="text-center mt-3">
                    <p class="m-0">{{ nft.name }}</p>
                  </div>
                  <div class="text-center">
                    <p class="m-0">Times Bred: {{ nft.breeds }}</p>
                  </div>
                  <div class="text-center" v-if="nft.breeds <= 4">
                    <p
                      class="m-0"
                      style="color: lightgreen"
                      v-if="nft.time <= new Date().getTime()"
                    >
                      Available
                    </p>
                    <p class="m-0" style="color: red" v-else>
                      {{ nft.format_time }}
                    </p>
                  </div>
                  <div class="text-center" v-else>
                    <p
                      class="m-0"
                      style="color: lightgreen"
                    >
                    Breeding Limit Reached
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="modal-footer d-flex justify-content-between pt-0">
            <button
              class="btn btn-secondary order-1 order-sm-1 order-md-0 px-3"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" id="modalSelect2">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header d-block">
            <div class="d-flex justify-content-between align-items-center">
              <h3 class="fw-bold mb-0">Breed NFT</h3>
              <img
                src="/img/icon/Close.svg"
                class="primary-icon"
                data-bs-dismiss="modal"
                alt=""
              />
            </div>
            <p class="m-0 text-muted">
              Select the second NFT you want to breed
            </p>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="text-center" v-if="Object.keys(nfts).length == 0">
                <i v-if="!errorNfts" class="fas fa-spin fa-spinner"></i>
                <div v-else class="text-center error-text">
                  You don't have at least 2 NFTs available to Breed!
                </div>
              </div>
              <div
                @click="selectNft(nft, 2)"
                class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3"
                v-for="nft in loadedNfts()"
                :key="nft.id"
                data-bs-dismiss="modal"
              >
                <div class="list-collection">
                  <div
                    class="collection-img"
                    :style="'background-image: url(' + nft.image + ');'"
                  ></div>
                  <div class="text-center mt-3">
                    <p class="m-0">{{ nft.name }}</p>
                  </div>
                  <div class="text-center">
                    <p class="m-0">Times Bred: {{ nft.breeds }}</p>
                  </div>
                  <div class="text-center" v-if="nft.breeds <= 4">
                    <p
                      class="m-0"
                      style="color: lightgreen"
                      v-if="nft.time <= new Date().getTime()"
                    >
                      Available
                    </p>
                    <p class="m-0" style="color: red" v-else>
                      {{ nft.format_time }}
                    </p>
                  </div>
                  <div class="text-center" v-else>
                    <p
                      class="m-0"
                      style="color: lightgreen"
                    >
                    Breeding Limit Reached
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="modal-footer d-flex justify-content-between pt-0">
            <button
              class="btn btn-secondary order-1 order-sm-1 order-md-0 px-3"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// CSS IMPORTS
import "@/assets/bootstrap.min.css";
import "@/assets/style.css";

// STYLED COMPONENTS
import { BreedButton } from "./styled-components/BreedButton";
import { ConnectButton } from "./styled-components/ConnectButton";
import { ImagesContainer } from "./styled-components/ImagesContainer";
import { Main } from "./styled-components/Main";
import { Navbar } from "./styled-components/Navbar";
import { Container } from "./styled-components/Container";

// IMAGES
import zilla from "./images/0.png";
import logo from "./images/logo.png";
import egg from "./images/egg.png";
import radLogo from "./images/RAD-logo.png";
import phantom_icon from "./images/phantom-icon.png";
import solflare_icon from "./images/solflare-icon.svg";

// IMPORTS
import { Connection } from "@solana/web3.js";
import axios from "axios";

export default {
  name: "App",
  data: () => {
    return {
      zilla,
      logo,
      egg,
      radLogo,
      phantom_icon,
      solflare_icon,

      nfts: {},
      service: null,
      interval: null,

      loadingNfts: false,
      wallet: "",
      breeding_key: "3zfsDNDScrr7abTxQ4Lo71iAJBd32gD51gfDTEHCDjP9",
      //breeding_key: "HsToSR5mfX5M5aeLec1XP2C9XJFe4gM2jxMFQ94SF6oK", 
      nftSelected1: {},
      nftSelected2: {},

      errorNfts: false,
      showOptions: false,
      price:0, 
    };
  },
  methods: {
    errorNoWallet() {
      return this.notif(
        "error",
        `You need to connect through a wallet to begin Breeding!`
      );
    },

    async connectWallet(type) {
      const websites = {
        solana: "https://phantom.app",
        solflare: "https://solflare.com",
      };
      if (type in window) {
        try {
          await window[type].connect();
        } catch (e) {
          console.error(e);
          this.notif(
            "error",
            `An error ocurred while getting ${type} extension!`
          );
        }
      } else window.open(websites[type], "_blank");
    },

    selectNft(nft, type) {
      if (
        (type === 1 &&
          Object.keys(this.nftSelected2).length > 0 &&
          nft.mint === this.nftSelected2.mint) ||
        (type === 2 &&
          Object.keys(this.nftSelected1).length > 0 &&
          nft.mint === this.nftSelected1.mint)
      )
        return this.notif("error", `Please select a different NFT to breed!`);

      if (nft.time >= new Date().getTime())
        return this.notif(
          "error",
          `You need to wait <strong>${this.lockedTime(
            nft.time
          )}</strong> before breeding this NFT again!`
        );

      if (type === 1) this.nftSelected1 = nft;
      if (type === 2) this.nftSelected2 = nft;
    },

    async loadNfts(nfts) {
      this.loadingNfts = true;
      let index = 0;
      for (let nft of nfts) {
        index++;
        let obj = {
          index,
          name: nft.metadata.data.name,
          mint: nft.metadata.mint,
          breed_data: {
            mint: nft.mint,
            tokenAccount: nft.tokenAccount,
          },
          breeds: nft.children,
          time: parseInt(nft.lockedTimestamp * 1000),
          image: zilla,
        };
        if (nft.lockedTimestamp > 0)
          obj.format_time = this.lockedTime(
            parseInt(nft.lockedTimestamp * 1000)
          );
        this.$set(this.nfts, nft.metadata.mint, obj);
        this.loadNftImage(nft.metadata.mint, nft.metadata.data.uri);
      }
      this.loadingNfts = false;
    },

    timeInt() {
      if (Object.keys(this.nfts).length > 0) {
        for (let x in this.nfts) {
          if (this.nfts[x].time == 0) continue;
          let curr = this.nfts[x];
          curr.format_time = this.lockedTime(curr.time);
          this.$set(this.nfts, curr.mint, curr);
        }
      }
    },

    async loadNftImage(mint, uri) {
      let resp = await axios.get(uri);
      if (resp.data && resp.data.image) this.nfts[mint].image = resp.data.image;
    },

    loadedNfts() {
      let nfts = [];
      for(let x in this.nfts) {
        nfts.push(this.nfts[x]);
      }
      nfts.sort((a,b) => {
        return a.time - b.time;
      });
      return nfts;
    },

    async breedNow() {
      if (
        Object.keys(this.nftSelected1).length === 0 ||
        Object.keys(this.nftSelected2).length === 0
      )
        return this.notif("error", `You need to select 2 NFTs to Breed!`);

      try {
        const resp = await this.service.breed(
          this.breeding_key,
          this.nftSelected1.breed_data,
          this.nftSelected2.breed_data,
          window[this.wallet.type]
        );

        this.notif("success", `You have successfully bred!`);

        this.nftSelected1 = {};
        this.nftSelected2 = {};
      } catch (e) {
        console.error(e);
        if (e.code === 4001)
          return this.notif(
            "error",
            `You have cancelled the Phantom transaction!`
          );
        else if (e.code === -32603)
          return this.notif(
            "error",
            `Something went wrong confirming the transaction (rejected / other)!`
          );
        else if (e.toString().includes("Failed to sign transaction"))
          return this.notif(
            "error",
            `You have cancelled the Solflare transaction!`
          );
        else if (e.type && e.type.includes("Insufficient Funds"))
          return this.notif(
            "error",
            `You don't have SOL or $RAD to fund the transaction!`
          );
        this.notif(
          "error",
          `An error ocurred while Breeding. Contact an Administrator!`
        );
      }
    },

    async loadNewNfts() {
      if (this.loadingNfts) return;
      this.errorNfts = false;
      try {
        let verified_nfts = await this.service.getVerifiedNfts(
          this.breeding_key,
          this.wallet.key
        );

        let available_nfts = await this.service.filterAvailableNfts(
          this.breeding_key,
          verified_nfts
        );

        if (available_nfts.length < 2) {
          this.errorNfts = true;
          return this.notif(
            "error",
            `You don't have at least 2 NFTs to breed!`
          );
        }

        this.nfts = {};
        this.loadNfts(verified_nfts);
      } catch (e) {
        console.error(e);
      }
    },

    gotWallet(type) {
      this.wallet = { type, key: window[type].publicKey };

      const connection = new Connection(
        "https://ssc-dao.genesysgo.net/",
        "confirmed"
      );
      this.service = new window.solport.NftBreedingService(connection);
      this.get_price();
    },

    notif(type, msg) {
      this.$notify({
        group: "alerts",
        type: type == "success" ? "alert" : type,
        title: type == "success" ? "Success" : "Error",
        text: msg,
      });

      console.log(type, msg);
    },

    lockedTime(timestamp) {
      let now = parseInt(new Date().getTime() / 1000) * 1000;
      let second = parseInt((timestamp - now) / 1000);
      let days = parseInt(second / 86400);
      let hours = parseInt((second % 86400) / 3600);
      let minutes = parseInt(((second % 86400) % 3600) / 60);
      let seconds = parseInt(((second % 86400) % 3600) % 60);
      return `${days}D ${hours}H ${minutes}M ${seconds}S`;
    },
    async get_price(){
      const response = await this.service.getBreedConfig(this.breeding_key);
      //console.log(window.solport.SolanaHelper)
      this.price = this.service.lamportsToToken(response.price);

    }
  },
  components: {
    BreedButton,
    ConnectButton,
    ImagesContainer,
    Main,
    Navbar,
    Container,
  },
  created() {
    setInterval(() => {
      this.timeInt();
    }, 1000);
  },
  mounted() {
    this.$nextTick(() => {
      window.onload = async () => {
        window.solana.on("connect", () => {
          console.log(`Solana connected!`);
          this.gotWallet("solana");
        });

        window.solflare.on("connect", () => {
          console.log(`Solflare connected!`);
          this.gotWallet("solflare");
        });
      };
    });
  },
};
</script>