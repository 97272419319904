import styled from "vue-styled-components";

export const ImagesContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
margin-button: 48px;
flex-wrap: wrap;
max-width: 992px;

.zilla-card {
  max-width: 300px;
  width: 100%;
  padding: 7px;
  background-color: #a300a2;
  border: 7px solid #4d0054;
  box-shadow: 12px 12px 0 rgba(0, 0, 0, 0.7);

  img {
    max-width: 100%;
    height: auto;
    border: 14px solid #000;
  }

  .card-body {
    padding: 24px;
    background-color: black;
    color: #a300a2;
    font-size: 36px;
    text-transform: uppercase;
    color: #edf000;
    text-align: center;

    button {
      margin: auto;
      color: #edf000;
      border: 2px solid #ffffff40;
      font-size: 36px;
      font-weight: 600;
      text-transform: uppercase;
      border-radius: 6px;
      height: 50px;
      line-height: 50px;
      padding: 0 36px;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        border: 2px solid #ffffffa8;
      }
    }
  }
}

.middle-container {
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .details {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    padding: 24px;
    background-color: #00000047;
    border-radius: 5px;

    img {
      max-width: 36px;
      max-height: 36px;
      margin-right: 16px;
    }
  }
}

@media (min-width: 992px) {
  .zilla-card {
    flex-basis: 30%;
    max-width: 30%;
  }

  .middle-container {
    flex-basis: 40%;
    max-width: 40%;
  }
}
`;