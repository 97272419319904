import styled from "vue-styled-components";

export const Main = styled.main`
flex-grow: 1;
display: flex;
flex-direction: column;
align-items: center;
padding: 0 32px;

h1 {
  width: 100%;
  padding: 48px 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 48px;
  color: #fff;
  background-color: #0000002e;
  text-shadow: rgb(163, 0, 162) 0px 0px 10px, rgb(77, 0, 84) 0px 0px 10px;
  letter-spacing: 2px;
}
`;