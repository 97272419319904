import styled from "vue-styled-components";

import background from "../images/bg-1.png";

export const Container = styled.div`
min-height: 100vh;
display: flex;
flex-direction: column;
background-color: #0c0316;
background-image: linear-gradient(rgba(0, 0, 35, 0.25), rgba(0, 0, 35, 0.45)),
  url(${background});
background-size: cover;
background-position: bottom;
background-repeat: no-repeat;
`;