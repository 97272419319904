import styled from "vue-styled-components";

export const Navbar = styled.header`
width: 100%;
padding: 48px 64px 0;
background-color: #0000003b;

img {
  width: auto;
  max-height: 82px;
}
`;