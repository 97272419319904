import Vue from 'vue'
import App from './App.vue'

require("@solana/wallet-adapter-vue-ui/styles.css");

import Notifications from 'vue-notification'

Vue.config.productionTip = false

Vue.use(window['solport'])
Vue.use(Notifications)
new Vue({
  render: h => h(App),
}).$mount('#app')
