import styled from "vue-styled-components";

export const BreedButton = styled.button`
color: #000000;
border: 2px solid #ffffff;
font-size: 24px;
font-weight: 600;
text-transform: uppercase;
background-color: #edf000;
cursor: pointer;
border-radius: 6px;
height: 50px;
line-height: 50px;
padding: 0 36px;
cursor: pointer;
display: flex;
align-items: center;
font-family: "Inter", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;

margin-bottom: 48px;

img {
  max-width: 28px;
  max-height: 28px;
  margin-right: 16px;
}
`;